import {
  FIREBASE_PROVIDERS,
  FIREBASE_SSO_ERROR_CODES
} from "@constants/firebase_providers.constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store";

import { authAPI, FIREBASE_LOGIN_PAYLOAD } from "./authAPI";
import { USER_ROLES } from "@constants/user-roles";

export interface AccessControl {
  user_id: string;
  access_roles_code: string;
  permission_code: string;
  property_id?: string;
}

export interface UserState {
  email: string;
  first_name: string;
  last_name: string;
  uid: string;
  accessControl: AccessControl[];
  role: string;
  avatar_url?: string;
  hubspot_owner_id?: string;
  agent_hs_deal_url?: string;
  agent_hs_meeting_url?: string;
  default_agent_calendar_link?: string;
}

// interface for store state
export interface AuthState {
  firebaseUser: any;
  user: UserState | null;
  isLoggedIn: boolean;
  isLoading: boolean;
  isAuthenticated: boolean;
  loginError: null | string;
}

// initial state
const initialState: AuthState = {
  firebaseUser: null,
  user: null,
  isLoggedIn: false,
  isLoading: false,
  isAuthenticated: false,
  loginError: null
};

// middleware
export const signup = createAsyncThunk(
  "auth/signup",
  async (
    { username, email, password }: { username: string; email: string; password: string },
    thunkAPI
  ) => {
    try {
      const response = await authAPI.signup(username, email, password);
      return response;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const firebaseLogin = createAsyncThunk(
  "auth/firebaseLogin",
  async (
    { provider, payload }: { provider: FIREBASE_PROVIDERS; payload?: FIREBASE_LOGIN_PAYLOAD },
    thunkAPI
  ) => {
    try {
      const user: UserState = await authAPI.firebaseLogin(provider, payload);
      if (!user) throw new Error("No details found");
      return user;
    } catch (error: any) {
      console.error(error);
      return thunkAPI.rejectWithValue({ error: error.code });
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    await authAPI.logout();
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue({ error });
  }
});

const getUserRoleBasedOnAccessControl = (accessControl: AccessControl[]) => {
  if (accessControl.length) {
    const response = accessControl.find(
      (access) => access.access_roles_code === USER_ROLES.SUPER_ADMIN.toLocaleUpperCase()
    );
    return response ? response.access_roles_code : USER_ROLES.AGENT.toLocaleUpperCase();
  }
  return "";
};

// authSlice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUser: (state, action) => {
      state.user = {
        ...action.payload,
        role: getUserRoleBasedOnAccessControl(action.payload.accessControl)
      };
      state.isLoggedIn = true;
      state.isLoading = false;
      state.isAuthenticated = true;
    },
    setLoading: (state) => {
      state.isLoading = true;
    },
    setAuthenticated: (state) => {
      state.isAuthenticated = true;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(signup.fulfilled, (state) => {
      state.isLoggedIn = true;
    });
    builder.addCase(signup.rejected, (state) => {
      state.isLoggedIn = false;
    });
    builder.addCase(firebaseLogin.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.user = {
        ...action.payload,
        role: getUserRoleBasedOnAccessControl(action.payload.accessControl)
      };
      state.loginError = null;
    });
    builder.addCase(firebaseLogin.rejected, (state, action) => {
      let errorMsg = "";
      switch ((action as any).payload.error) {
        case FIREBASE_SSO_ERROR_CODES.WRONG_PASSWORD:
          errorMsg = "Invalid credentials";
          break;
        case FIREBASE_SSO_ERROR_CODES.USER_NOT_FOUND:
          errorMsg = "Invalid credentials";
          break;
        case FIREBASE_SSO_ERROR_CODES.POPUP_CLOSED_BY_USER:
          errorMsg = "The popup has been closed before finalizing the operation";
          break;
        case FIREBASE_SSO_ERROR_CODES.ACCOUNT_EXISTS_WITH_DIFF_CRED:
          errorMsg = "Single sign-on not enabled";
          break;
        case FIREBASE_SSO_ERROR_CODES.POPUP_BLOCKED:
          errorMsg = "Popup blocked by browser";
          break;
        default:
          errorMsg = "Some error occurred";
          break;
      }
      state.loginError = errorMsg;
      state.isLoggedIn = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isLoggedIn = false;
      state.user = null;
    });
    builder.addMatcher(
      (action) => action.type.endsWith("/pending"),
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addMatcher(
      (action) => action.type.endsWith("/fulfilled") || action.type.endsWith("/rejected"),
      (state) => {
        state.isLoading = false;
      }
    );
  }
});

// export actions from slice if any reducers created
export const { setUser, setLoading, setAuthenticated } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const isLoggedInFromStore = (state: RootState) => state.auth.isLoggedIn;

export const userDetailsFromStore = (state: RootState) => state.auth.user;

export const loginErrorFromStore = (state: RootState) => state.auth.loginError;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/** export const loginThunk = (): AppThunk => (dispatch, getState) => {
  const isLogged = isLoggedInFromStore(getState());
  if (isLogged) {
    // dispatch action here
  }
}; */

export default authSlice.reducer;
