/**
 * Check access_permissions table for descriptions w.r.t. each permission
 */
export const enum AccessUserPermissions {
  PROPERTY_ALL_DATA_READ = "PROPERTY_ALL-DATA_READ",
  PROPERTY_ALL_DATA_UPDATE = "PROPERTY_ALL-DATA_UPDATE",
  PROPERTY_ALL_SECTIONS_READ = "PROPERTY_ALL-SECTIONS_READ",
  PROPERTY_ALL_SECTIONS_UPDATE = "PROPERTY_ALL-SECTIONS_UPDATE",
  PROPERTY_AGENT_ASSIGNMENT_UPDATE = "PROPERTY_AGENT-ASSIGNMENT_UPDATE",
  PROPERTY_ALL_MEDIA_READ = "PROPERTY_ALL-MEDIA_READ",
  PROPERTY_ALL_MEDIA_UPDATE = "PROPERTY_ALL-MEDIA_UPDATE",
  PROPERTY_EXTERNAL_PLATFORMS_READ = "PROPERTY_EXTERNAL-PLATFORMS_READ",
  PROPERTY_EXTERNAL_PLATFORMS_UPDATE = "PROPERTY_EXTERNAL-PLATFORMS_UPDATE",
  PROPERTY_GENERAL_NOTES_UPDATE = "PROPERTY_GENERAL-NOTES_UPDATE",
  PROPERTY_MARKETING_DETAILS_UPDATE = "PROPERTY_MARKETING-DETAILS_UPDATE",
  PROPERTY_COUNCIL_TAX_UPDATE = "PROPERTY_COUNCIL-TAX_UPDATE",
  PROPERTY_PROPERTY_TYPE_UPDATE = "PROPERTY_PROPERTY-TYPE_UPDATE",
  PROPERTY_ROOMS_UPDATE = "PROPERTY_ROOMS_UPDATE",
  PROPERTY_FEATURES_UPDATE = "PROPERTY_FEATURES_UPDATE",
  PROPERTY_RULES_UPDATE = "PROPERTY_RULES_UPDATE",
  PROPERTY_PARKING_UPDATE = "PROPERTY_PARKING_UPDATE",
  PROPERTY_LISTING_DESCRIPTION_UPDATE = "PROPERTY_LISTING-DESCRIPTION_UPDATE",
  PROPERTY_OWNERSHIP_UPDATE = "PROPERTY_OWNERSHIP_UPDATE",
  ALL_USERS_DATA_READ = "ALL_USERS-DATA_READ",
  ALL_USERS_DATA_UPDATE = "ALL_USERS-DATA_UPDATE",
  ALL_USERS_IMPERSONATE = "ALL_USERS-IMPERSONATE",
  PROPERTY_PROPERTY_MAIN_BULLET_POINTS_UPDATE = "PROPERTY_PROPERTY-MAIN_BULLET_POINTS_UPDATE",
  PROPERTY_STREET_VIEW_UPDATE = "PROPERTY_STREET-VIEW_UPDATE",
  PROPERTY_SALES_ALL_DATA_READ = "PROPERTY_SALES-ALL-DATA_READ",
  PROPERTY_SALES_ALL_DATA_UPDATE = "PROPERTY_SALES-ALL-DATA_UPDATE",
  PROPERTY_SALES_ALL_SECTIONS_READ = "PROPERTY_SALES-ALL-SECTIONS_READ",
  PROPERTY_SALES_ALL_SECTIONS_UPDATE = "PROPERTY_SALES-ALL-SECTIONS_UPDATE",
  PROPERTY_SALES_MARKETING_DETAILS_UPDATE = "PROPERTY_SALES-MARKETING-DETAILS_UPDATE",
  PROPERTY_SALES_MAIN_BULLET_POINTS_READ = "PROPERTY-SALES_MAIN-BULLET-POINTS_READ",
  PROPERTY_SALES_MAIN_BULLET_POINTS_UPDATE = "PROPERTY-SALES_MAIN-BULLET-POINTS_UPDATE",
  PROPERTY_SALES_LISTING_DESCRIPTION_READ = "PROPERTY-SALES_LISTING-DESCRIPTION_READ",
  PROPERTY_SALES_LISTING_DESCRIPTION_UPDATE = "PROPERTY-SALES_LISTING-DESCRIPTION_UPDATE",
  PROPERTY_SALES_GAS_SUPPLY_UPDATE = "PROPERTY-SALES_GAS-SUPPLY_UPDATE",
  PROPERTY_EPC_UPDATE = "PROPERTY_EPC_UPDATE",
  PROPERTY_SALES_NOTES_READ = "PROPERTY-SALES_NOTES_READ",
  PROPERTY_SALES_NOTES_UPDATE = "PROPERTY-SALES_NOTES_UPDATE",
  PROPERTY_SALES_OWNERSHIP_UPDATE = "PROPERTY-SALES_OWNERSHIP_UPDATE",
  PROPERTY_SALES_AGENT_ASSIGNMENT_UPDATE = "PROPERTY-SALES_AGENT-ASSIGNMENT_UPDATE",
  PROPERTY_SALES_MEDIA_READ = "PROPERTY-SALES_MEDIA_READ",
  PROPERTY_SALES_MEDIA_UPDATE = "PROPERTY-SALES_MEDIA_UPDATE",
  OFFER_ALL_DATA_ALL_PROPERTY_READ = "OFFER_ALL-DATA-ALL-PROPERTY_READ",
  OFFER_ALL_DATA_ACTIONS_ALL_PROPERTY_UPDATE = "OFFER_ALL-DATA-ACTIONS-ALL-PROPERTY_UPDATE",
  OFFER_ALL_DATA_SINGLE_PROPERTY_READ = "OFFER_ALL-DATA-SINGLE-PROPERTY_READ",
  OFFER_ALL_DATA_SINGLE_PROPERTY_UPDATE = "OFFER_ALL-DATA-SINGLE-PROPERTY_UPDATE",
  OFFER_ALL_ACTIONS_SINGLE_PROPERTY_UPDATE = "OFFER_ALL-ACTIONS-SINGLE-PROPERTY_UPDATE",
  OFFER_EXPIRY_UPDATE = "OFFER_EXPIRY_UPDATE",
  PROPERTY_VIEWING_AGENT_ASSIGNMENT_UPDATE = "PROPERTY_VIEWING-AGENT-ASSIGNMENT_UPDATE",
  PROPERTY_SALES_VIEWING_AGENT_ASSIGNMENT_UPDATE = "PROPERTY-SALES_VIEWING-AGENT-ASSIGNMENT_UPDATE",
  PROPERTY_ENQUIRY_READ = "PROPERTY_ENQUIRY_READ",
  PROPERTY_VIEWING_URL_UPDATE = "PROPERTY_VIEWING-URL_UPDATE",
  PROPERTY_SALES_VIEWING_URL_UPDATE = "PROPERTY-SALES_VIEWING-URL_UPDATE",
  PROPERTY_MANAGER_ASSIGNMENT_UPDATE = "PROPERTY_PROPERTY-MANAGER-ASSIGNMENT_UPDATE",
  PROPERTY_LANDLORD_IMPERSONATE = "PROPERTY_LANDLORD_IMPERSONATE"
}
